/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_tag helper in views (e.g <%= image_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
//const images = require.context('../images', true)
//const imagePath = (name) => images(name, true)

require("@rails/ujs").start()
global.Rails = Rails;
require("@rails/activestorage").start()
require('jquery-ui-bundle')
require("easy-autocomplete")
//require("jquery.scrollto")
require("owl.carousel")

var jQuery = require('jquery')

import "core-js/stable";

import 'bootstrap';

import '../src/jquery.modern-blink';
import '../src/isInViewport.min';
import '../src/sweet-alert-confirm';
import '../src/modernizr-custom';

import '../stylesheets/application'

require("flatpickr");
import flatpickr from "flatpickr";
import { de } from "flatpickr/dist/l10n/de"
import { en } from "flatpickr/dist/l10n/uk"
import { es } from "flatpickr/dist/l10n/es"

import { Fancybox } from "@fancyapps/ui";

//import 'intl-tel-input/build/js/utils'
import intlTelInput from 'intl-tel-input/build/js/intlTelInput-jquery.min';

require("./home");
require("./pictures")
require("./tasks")
require("./projects")
require("./documents")
require("./suppliers")

$(function () {
    $('.table-responsive').on('shown.bs.dropdown', function (e) {
        var $table = $(this),
            $menu = $(e.target).find('.dropdown-menu'),
            tableOffsetHeight = $table.offset().top + $table.height(),
            menuOffsetHeight = $menu.offset().top + $menu.outerHeight(true);

        if (menuOffsetHeight > tableOffsetHeight)
            $table.css("padding-bottom", menuOffsetHeight - tableOffsetHeight);
    });

    $('.flatpickr').each(function () {
        $(this).flatpickr({"locale": $(this).attr('data-locale'), "enableTime": $(this).attr('data-enabletime'), "altFormat": $(this).attr('data-dateformat'), "altInput": "true"});
    });

    $('.table-responsive').on('hide.bs.dropdown', function () {
        $(this).css("padding-bottom", 0);
    });

    $('.blink').modernBlink({iterationCount: 4});

    $(window).resize(function () {
        return $("#main-canvas").css("min-height", $(window).height() - 375);
    });

    $("#main-canvas").css("min-height", $(window).height() - 375);

    $('#inside_tab_link').change(function () {
        if ($(this).val().length > 0) {
            return document.location.href = $(this).val();
        }
    });
    $('#dynamic_search').keyup(function (e) {
        if (window.search_submit_timer) {
            clearTimeout(window.search_submit_timer);
        }
        return window.search_submit_timer = setTimeout(function () {
                $('#dynamic_search').closest('form')[0].requestSubmit();
            },
            300
        );
    });
});
