(function () {
    $(function () {
        $('#tasks_supplier').change(function () {
            return $(this).closest('form').submit();
        });
        $('#tasks_project').change(function () {
            return $(this).closest('form').submit();
        });
        $('#tasks_area').change(function () {
            return $(this).closest('form').submit();
        });
        $('#tasks_group').change(function () {
            return $(this).closest('form').submit();
        });

        $input = $("input#task_supplier_name")
        var options = {
            url: function (phrase) {
                return "/suppliers/autocomplete_full_name.json?q=" + phrase;
            },
            getValue: "name",
            requestDelay: 300,
            list: {
                maxNumberOfElements: 100,
                onChooseEvent: function () {
                    var value = $("input#task_supplier_name").getSelectedItemData().id;
                    $("#task_supplier_id").val(value);
                }
            }
        };
        $input.easyAutocomplete(options);
    });
}).call(this);