(function () {
    $(function () {
        $('.upload_datafile').change(function () {
            if ($(this).val() !== "") {
                $('.fileUpload').css('background-color', '#fff');
                $('.fileUpload').css("color", "#fff");
                return $(this).closest('form').submit();
            }
        });

        $('#document_area_id').change(function () {
            if ($('#document_area_id').val() === "") {
                return $('#document_projects_supplier_id_container').hide();
            } else {
                return $.getScript('/' + $('#document_area_id').attr('data-locale') + '/projects/' + $('#document_area_id').attr('data-project-id') + '/documents/get_suppliers/' + '?area_id=' + $('#document_area_id').val());
            }
        });

        $input = $("input#document_supplier_name")
        var options = {
            url: function (phrase) {
                return "/suppliers/autocomplete_full_name.json?q=" + phrase;
            },
            getValue: "name",
            requestDelay: 300,
            list: {
                maxNumberOfElements: 100,
                onChooseEvent: function () {
                    var value = $("input#document_supplier_name").getSelectedItemData().id;
                    $("#document_supplier_id").val(value);
                }
            }
        };
        $input.easyAutocomplete(options);
    });
}).call(this);