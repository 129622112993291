(function() {
    $(function() {
        $(".picture-box").fadeIn();
        if ($('#infinite-scrolling').length > 0) {
            return $(window).on('scroll', function() {
                var more_images_url;
                more_images_url = $('.pagination .next_page').attr('href');
                if (more_images_url && $(window).scrollTop() > document.documentElement.scrollHeight - $(window).height() - 150) {
                    $('.pagination').empty();
                    return $.getScript(more_images_url);
                }
            });
        }
    });

}).call(this);