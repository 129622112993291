(function() {
    $(function() {
        $("#add-contact-btn").click(function() {
            return $.getScript("/suppliers/add_contact/?no=" + $("#contacts-container").children().length);
        });
        $(".del-contact-btn").click(function() {
            if ($(this).data("id")) {
                $.getScript("/suppliers/delete_contact/?contact_id=" + $(this).data("id"));
                $('input[value="' + $(this).data("id") + '"]').remove();
            }
            $(this).closest("div").fadeOut();
            return $(this).closest("div").remove();
        });
        return $('#dynamic_search').keyup(function(e) {
            if (window.search_submit_timer) {
                clearTimeout(window.search_submit_timer);
            }
            return window.search_submit_timer = setTimeout(function() {
                return $('#dynamic_search').closest('form').submit();
            }, 300);
        });
    });

}).call(this);