(function () {
    $(function () {
        var ScaleSlider1, back_to_top_button, jssor_1_SlideoTransitions, jssor_1_SlideshowTransitions, jssor_1_options, jssor_1_slider;

        $('.owl-carousel').owlCarousel({
            loop: true,
            margin: 75,
            nav: false,
            dots: false,
            autoplay: true,
            autoplayTimeout: 2000,
            autoplayHoverPause: false,
            autoplaySpeed: 800,
            animateIn: true,
            responsive: {
                0: {
                    items: 2
                }, 600: {
                    items: 3
                }, 1000: {
                    items: 5
                }
            }
        });
        $('#team-jonas').click(function () {
            $('#team-data').fadeOut(400, function () {
                $('#team-data').html($('#team-data-jonas').html());
                return $('#team-data').fadeIn();
            });
            $('#team-image').fadeOut(400, function () {
                $('#team-image').html($('#team-image-jonas').html());
                return $('#team-image').fadeIn();
            });
            return false;
        });
        $('#team-luca').click(function () {
            $('#team-data').fadeOut(400, function () {
                $('#team-data').html($('#team-data-luca').html());
                return $('#team-data').fadeIn();
            });
            $('#team-image').fadeOut(400, function () {
                $('#team-image').html($('#team-image-luca').html());
                return $('#team-image').fadeIn();
            });
            return false;
        });
        $('#team-sam').click(function () {
            $('#team-data').fadeOut(400, function () {
                $('#team-data').html($('#team-data-sam').html());
                return $('#team-data').fadeIn();
            });
            $('#team-image').fadeOut(400, function () {
                $('#team-image').html($('#team-image-sam').html());
                return $('#team-image').fadeIn();
            });
            return false;
        });
        $('#team-lorena').click(function () {
            $('#team-data').fadeOut(400, function () {
                $('#team-data').html($('#team-data-lorena').html());
                return $('#team-data').fadeIn();
            });
            $('#team-image').fadeOut(400, function () {
                $('#team-image').html($('#team-image-lorena').html());
                return $('#team-image').fadeIn();
            });
            return false;
        });
        $('#team-yolanda').click(function () {
            $('#team-data').fadeOut(400, function () {
                $('#team-data').html($('#team-data-yolanda').html());
                return $('#team-data').fadeIn();
            });
            $('#team-image').fadeOut(400, function () {
                $('#team-image').html($('#team-image-yolanda').html());
                return $('#team-image').fadeIn();
            });
            return false;
        });
        $('#team-irene').click(function () {
            $('#team-data').fadeOut(400, function () {
                $('#team-data').html($('#team-data-irene').html());
                return $('#team-data').fadeIn();
            });
            $('#team-image').fadeOut(400, function () {
                $('#team-image').html($('#team-image-irene').html());
                return $('#team-image').fadeIn();
            });
            return false;
        });
        $('#team-anna').click(function () {
            $('#team-data').fadeOut(400, function () {
                $('#team-data').html($('#team-data-anna').html());
                return $('#team-data').fadeIn();
            });
            $('#team-image').fadeOut(400, function () {
                $('#team-image').html($('#team-image-anna').html());
                return $('#team-image').fadeIn();
            });
            return false;
        });
        $('#team-ugo').click(function () {
            $('#team-data').fadeOut(400, function () {
                $('#team-data').html($('#team-data-ugo').html());
                return $('#team-data').fadeIn();
            });
            $('#team-image').fadeOut(400, function () {
                $('#team-image').html($('#team-image-ugo').html());
                return $('#team-image').fadeIn();
            });
            return false;
        });
        $('#team-uldis').click(function () {
            $('#team-data').fadeOut(400, function () {
                $('#team-data').html($('#team-data-uldis').html());
                return $('#team-data').fadeIn();
            });
            $('#team-image').fadeOut(400, function () {
                $('#team-image').html($('#team-image-uldis').html());
                return $('#team-image').fadeIn();
            });
            return false;
        });
        $('#team-barbara').click(function () {
            $('#team-data').fadeOut(400, function () {
                $('#team-data').html($('#team-data-barbara').html());
                return $('#team-data').fadeIn();
            });
            $('#team-image').fadeOut(400, function () {
                $('#team-image').html($('#team-image-barbara').html());
                return $('#team-image').fadeIn();
            });
            return false;
        });
        $('#team-timothy').click(function () {
            $('#team-data').fadeOut(400, function () {
                $('#team-data').html($('#team-data-timothy').html());
                return $('#team-data').fadeIn();
            });
            $('#team-image').fadeOut(400, function () {
                $('#team-image').html($('#team-image-timothy').html());
                return $('#team-image').fadeIn();
            });
            return false;
        });
        $('#team-elena').click(function () {
            $('#team-data').fadeOut(400, function () {
                $('#team-data').html($('#team-data-elena').html());
                return $('#team-data').fadeIn();
            });
            $('#team-image').fadeOut(400, function () {
                $('#team-image').html($('#team-image-elena').html());
                return $('#team-image').fadeIn();
            });
            return false;
        });
        $('#team-data').html($('#team-data-jonas').html());
        $('#team-image').html($('#team-image-jonas').html());

        $("#about-us-tabs").tabs();

        //$("#accordion").accordion();
        // $("a.fb-iframe").fancybox({
        //     type: 'iframe',
        //     width: $('.container').width(),
        //     height: $('.container').width()
        // });
        $('#accordion').bind('accordionactivate', function (event, ui) {
            return $('body,html').animate({
                scrollTop: $(ui.newHeader).offset().top - 200
            }, 800);
        });
        back_to_top_button = ['<a href="#top" class="back-to-top"><i class="fa fa-chevron-up d-print-none" aria-hidden="true"></i></a>'].join("");
        $("body").append(back_to_top_button);
        $(".back-to-top").hide();
        $(window).on('scroll', function () {
            if ($(this).scrollTop() > 100) {
                $('.back-to-top').fadeIn();
            } else {
                $('.back-to-top').fadeOut();
            }
            $('video').each(function () {
                if ($(this).is(":in-viewport")) {
                    return $(this)[0].play();
                } else {
                    return $(this)[0].pause();
                }
            });
            return $('.resize_me').each(function () {
                if ($(this).is(":in-viewport")) {
                    return $(this).css('font-size', '48px');
                }
            });
        });
        $('.back-to-top').click(function () {
            $('body,html').animate({
                scrollTop: 0
            }, 800);
            return false;
        });

        $("#form_phone_brochure").intlTelInput({
            preferredCountries: [],
            utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.19/js/utils.js",
            nationalMode: false,
            initialCountry: "auto",
            separateDialCode: true,
            hiddenInput: "phone_full",
            geoIpLookup: function(callback) {
                return callback($('#form_phone_brochure').attr('data-country'));
            }
        });

        $('#brochure-form').submit(function() {
            var filter;
            filter = /^[\w\-\.\+]+\@[a-zA-Z0-9\.\-]+\.[a-zA-z0-9]{2,4}$/;
            if ($('#form_name').val().length === 0) {
                $('#form_name').css("border", "2px solid #ff0000");
                alert($('#form_name').attr('data-errormsg'));
                return false;
            } else {
                $('#form_name').css("border", "1px solid #cccccc");
            }
            if ($('#form_email').val().length === 0 || !filter.test($('#form_email').val())) {
                $('#form_email').css("border", "2px solid #ff0000");
                alert($('#form_email').attr('data-errormsg'));
                return false;
            } else {
                $('#form_email').css("border", "1px solid #cccccc");
            }
            if (!$('#form_phone_brochure').intlTelInput("isValidNumber")) {
                $('#form_phone_brochure').css("border", "2px solid #ff0000");
                alert($('#form_phone_brochure').attr('data-errormsg'));
                return false;
            } else {
                $('#form_phone_brochure').css("border", "1px solid #cccccc");
            }
            if (!$('#form_privacy_policy').prop("checked")) {
                $('#privacy_policy_border').css("border", "2px solid #ff0000");
                alert($('#form_privacy_policy').attr('data-errormsg'));
                return false;
            } else {
                $('#privacy_policy_border').css("border", "none");
            }
            if (grecaptcha.getResponse().length === 0) {
                alert($('#recaptcha-div').attr('data-errormsg'));
                return false;
            }
        });

        $("#form_phone_contact").intlTelInput({
            preferredCountries: [],
            utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.19/js/utils.js",
            nationalMode: false,
            initialCountry: "auto",
            separateDialCode: true,
            hiddenInput: "phone_full",
            geoIpLookup: function (callback) {
                return callback($('#form_phone_contact').attr('data-country'));
            }
        });
        $('#contact-form').submit(function () {
            var filter;
            filter = /^[\w\-\.\+]+\@[a-zA-Z0-9\.\-]+\.[a-zA-z0-9]{2,4}$/;
            if ($('#form_name').val().length === 0) {
                $('#form_name').css("border", "2px solid #ff0000");
                alert($('#form_name').attr('data-errormsg'));
                return false;
            } else {
                $('#form_name').css("border", "1px solid #cccccc");
            }
            if ($('#form_email').val().length === 0 || !filter.test($('#form_email').val())) {
                $('#form_email').css("border", "2px solid #ff0000");
                alert($('#form_email').attr('data-errormsg'));
                return false;
            } else {
                $('#form_email').css("border", "1px solid #cccccc");
            }
            if (!$('#form_phone_contact').intlTelInput("isValidNumber")) {
                $('#form_phone_contact').css("border", "2px solid #ff0000");
                alert($('#form_phone_contact').attr('data-errormsg'));
                return false;
            } else {
                $('#form_phone_contact').css("border", "1px solid #cccccc");
            }
            if ($('#form_message').val().length === 0) {
                $('#form_message').css("border", "2px solid #ff0000");
                alert($('#form_message').attr('data-errormsg'));
                return false;
            } else {
                $('#form_message').css("border", "1px solid #cccccc");
            }
            if (!$('#form_privacy_policy').prop("checked")) {
                $('#privacy_policy_border').css("border", "2px solid #ff0000");
                alert($('#form_privacy_policy').attr('data-errormsg'));
                return false;
            } else {
                $('#privacy_policy_border').css("border", "none");
            }
            if (grecaptcha.getResponse().length === 0) {
                alert($('#recaptcha-div').attr('data-errormsg'));
                return false;
            }
            return true;
        });

        jssor_1_SlideshowTransitions = [{$Duration: 600, $Delay: 100, $Cols: 10, $Formation: $JssorSlideshowFormations$.$FormationStraight, $Opacity: 2}];
        jssor_1_SlideoTransitions = [[{
            b: -1, d: 1, o: -1, sX: -0.5, sY: -0.5
        }, {
            b: 0, d: 1000, y: -284, o: 1, sX: 0.5, sY: 0.5
        }]];
        jssor_1_options = {
            $AutoPlay: true, $FillMode: 2, $PauseOnHover: 0, $LazyLoading: 1, $SlideshowOptions: {
                $Class: $JssorSlideshowRunner$, $Transitions: jssor_1_SlideshowTransitions, $TransitionsOrder: 1
            }, $CaptionSliderOptions: {
                $Class: $JssorCaptionSlideo$, $Transitions: jssor_1_SlideoTransitions
            }, $ArrowNavigatorOptions: {
                $Class: $JssorArrowNavigator$
            }, $BulletNavigatorOptions: {
                $Class: $JssorBulletNavigator$
            }, $ThumbnailNavigatorOptions: {
                $Class: $JssorThumbnailNavigator$, $Cols: 10, $SpacingX: 8, $SpacingY: 8, $Align: 814
            }
        };
        if ($('#jssor_1').length > 0) {
            jssor_1_slider = new $JssorSlider$("jssor_1", jssor_1_options);
            ScaleSlider1 = function () {
                var refSize;
                refSize = jssor_1_slider.$Elmt.parentNode.clientWidth;
                if (refSize) {
                    return jssor_1_slider.$ScaleWidth(refSize);
                } else {
                    return window.setTimeout(ScaleSlider1, 30);
                }
            };
            ScaleSlider1();
            $(window).bind("load", ScaleSlider1);
            $(window).bind("resize", ScaleSlider1);
            return $(window).bind("orientationchange", ScaleSlider1);
        }
    });

    window.initMap = function () {
        var areas, i, image, map, _i, _j, _ref, _ref1;
        if ($('#maps-container').length === 0) {
            return false;
        }
        map = new google.maps.Map(document.getElementById('maps-container'), {
            center: {
                lat: 36.479, lng: -5.034
            }, zoom: 15, mapTypeControl: true, scrollwheel: false
        });
        if ($('#maps-container.maps-project').length > 0) {
            areas = $.parseJSON($('#maps-container.maps-project').attr('data-positions'));
            for (i = _i = 0, _ref = areas.length; 0 <= _ref ? _i < _ref : _i > _ref; i = 0 <= _ref ? ++_i : --_i) {
                new google.maps.Marker({
                    position: areas[i]['position'], map: map
                });
            }
            map.setCenter(areas[Math.floor(areas.length / 2)]['position']);
        } else {
            image = '/logo_tricom_icon.png';
            areas = [{
                "position": {
                    "lat": 36.479002, "lng": -5.038950
                }
            }, {
                "position": {
                    "lat": 36.484399, "lng": -5.035773
                }
            }, {
                "position": {
                    "lat": 36.471709, "lng": -5.042926
                }
            }];
            for (i = _j = 0, _ref1 = areas.length; 0 <= _ref1 ? _j < _ref1 : _j > _ref1; i = 0 <= _ref1 ? ++_j : --_j) {
                new google.maps.Marker({
                    position: areas[i]['position'], map: map, icon: image
                });
            }
            map.setCenter({
                "lat": 36.480656, "lng": -4.965164
            });
        }
        if ($(window).width() < 768) {
            map.setZoom(11);
        } else if ($(window).width() < 1200) {
            map.setZoom(12);
        } else {
            map.setZoom(13);
        }
        return map.setOptions({
            styles: [{
                "elementType": "geometry", "stylers": [{
                    "color": "#f5f5f5"
                }]
            }, {
                "elementType": "labels.icon", "stylers": [{
                    "visibility": "off"
                }]
            }, {
                "elementType": "labels.text.fill", "stylers": [{
                    "color": "#616161"
                }]
            }, {
                "elementType": "labels.text.stroke", "stylers": [{
                    "color": "#f5f5f5"
                }]
            }, {
                "featureType": "administrative", "elementType": "labels.text.stroke", "stylers": [{
                    "visibility": "off"
                }]
            }, {
                "featureType": "landscape", "elementType": "geometry", "stylers": [{
                    "color": "#faefd9"
                }]
            }, {
                "featureType": "landscape", "elementType": "labels.text.stroke", "stylers": [{
                    "visibility": "off"
                }]
            }, {
                "featureType": "poi", "stylers": [{
                    "visibility": "on"
                }]
            }, {
                "featureType": "poi", "elementType": "geometry", "stylers": [{
                    "color": "#dddeb6"
                }, {
                    "visibility": "on"
                }]
            }, {
                "featureType": "poi", "elementType": "labels.text.stroke", "stylers": [{
                    "visibility": "off"
                }]
            }, {
                "featureType": "road", "elementType": "geometry", "stylers": [{
                    "color": "#e7dcc4"
                }]
            }, {
                "featureType": "road", "elementType": "labels.text.stroke", "stylers": [{
                    "visibility": "off"
                }]
            }, {
                "featureType": "road.arterial", "elementType": "labels.text.fill", "stylers": [{
                    "color": "#757575"
                }]
            }, {
                "featureType": "road.highway", "elementType": "geometry", "stylers": [{
                    "color": "#dcc784"
                }]
            }, {
                "featureType": "road.highway", "elementType": "labels.text.fill", "stylers": [{
                    "color": "#616161"
                }]
            }, {
                "featureType": "road.local", "elementType": "labels.text.fill", "stylers": [{
                    "color": "#383737"
                }]
            }, {
                "featureType": "transit.line", "elementType": "geometry", "stylers": [{
                    "color": "#e5e5e5"
                }]
            }, {
                "featureType": "transit.station", "elementType": "geometry", "stylers": [{
                    "color": "#eeeeee"
                }]
            }, {
                "featureType": "water", "elementType": "geometry", "stylers": [{
                    "color": "#cebf9e"
                }]
            }, {
                "featureType": "water", "elementType": "labels.text.fill", "stylers": [{
                    "color": "#9e9e9e"
                }]
            }]
        });
    };

}).call(this);