(function () {
    $(function () {
        $(".sortable").sortable({
            handle: '.handle',
            update: function (e, ui) {
                Rails.ajax({
                    url: $(this).data("url"),
                    type: "PATCH",
                    data: $(this).sortable('serialize'),
                });
            }
        });
    });
}).call(this);